nav.nav-hidden {
    display: none;
}

nav.nav-shown {
    display: flex;
    // flex-direction: column;
    text-align: center;
    width: 100%;
    // min-width: 250px;
    background: transparent;
    border-right: 1px solid $soft-border;
    // background-color: $bluenav;
    // position:fixed;
    a{
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-bottom:0;
        cursor:pointer;
        &:hover{border-bottom:0}
    }
    .nav-link{cursor:pointer;}
}
footer {
    a{
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-bottom:0;
        cursor:pointer;
        &:hover{border-bottom:0;
            cursor:pointer;}
    }
}
nav ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    padding-inline-start: 0;
    li {
        display: flex;
        align-items: center;
        margin: 0 calc(#{$grid-unit} / 2);
        max-width: 200px;
        &:hover {
    
        }
        
    }
}
.prev-next h3{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.prev-next h3 a{text-align:center;}
.prev-next h3 a:first-of-type {
    border-bottom:1px solid #ccc;
    padding-bottom:.5rem;margin-bottom:.5rem
}
.prev-next span{
    display:none;
}
.prev-next svg{    font-size: .75em;
    margin: 0 0.5em;}
.prev-next h3{
    font-size: 18px;
}
.prev-next a:visited, nav a{
    color: #767676;
}
a {
    color: #6974c8;
    transition: all 0.8s;
}

a:hover{
    color: #2c3160;
}

.SRLCaptionText {
    padding: 0 1rem;
    width: fit-content;
    text-align:left !important;
}
.SRLElementWrapper img,
.SRLCaptionText {
    max-width: 95%;
}



@media screen and (min-width: 640px) {
    .prev-next h3{
        flex-direction:row;
    }
    .prev-next h3 a:first-of-type {
        border-bottom:0;
        padding-bottom:0;margin-bottom:0;
    }
    .prev-next span{
        display:flex;
        margin:1rem;
        color: #ccc;
    }
}

// nav .nav-link {
//     display: flex;
//     border: 0px solid #18ab29;
//     cursor: pointer;
//     width: 100px;
//     height: 44px;
//     padding-left: $grid-unit;
//     color: $hard-white;
//     font-weight: 700;
//     font-size: $menu-font-size;
//     align-items: center;
// }

// nav a {
//     text-decoration: none;
//     color: $hard-white;
// }

// .fauna-logo {
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-start;
//     height: var(--header-height);
//     max-height: var(--header-height);
//     color: $soft-white;
//     margin-bottom: calc(#{$grid-unit} / 2);
// }

// .fauna-logo .logo-container {
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     height: var(--header-height);
//     padding-left: calc(#{$grid-unit} / 2.0);
//     z-index: 30;
// }

// .fauna-logo .logo-container img {
//     height: var(--header-title-height);
// }
