.gridywrap{
  width:100%;
  padding: 0;
}
.thumbnail-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.gridywrap div[class*=gridy] {
  // padding: 15px; 
  // float: left;
  position: relative;
  background: white;
}
.gridy-item {
  width:100%; 
  height: auto; 
  overflow: hidden;
  padding: 1%;
  box-sizing: border-box;
}

// .gridywrap .gridy-1 {width: 100%;}
// .gridywrap .gridy-2 {width: 100%;}
// .gridywrap .gridy-3 {width: 100%;}

// .gridywrap .gridyhe-1 {height: 320px;}
// .gridywrap .gridyhe-2 {height: 320px;}

/*styling*/
.gridywrap .gridimg {
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  overflow: hidden;
  background-position: center center;
  // background-size: 90%;
  background-size: contain;
    background-repeat: no-repeat;
    padding: 1%;
    height: inherit;
    box-sizing: border-box;
  // margin: 2%;
  // box-shadow: inset 0 0 0 1px #ddd;
}
.gridywrap .gridimg.top-align {
  background-position: center 0;
}
.gridywrap .gridimg.cover {
  background-size: cover;
}
.gridywrap .gridimg img {
  width: 100%;
}
.gridywrap .gridinfo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 2px;
  transition: all 0.8s;
}
.gridywrap .gridinfo h3 {
  color: #fff;
  font-weight: bold;
  // text-transform: uppercase;
  // margin: 2rem 0 20px;
  position: relative;
  padding:0 1rem;
  font-size: 20px;
}
// .gridywrap .gridinfo h3:after {
//   content: '';
//   width: 25px;
//   border-top: 4px solid #fff;
//   display: block;
//   margin: 0 auto;
//   // margin-top: 25px;
// }
.gridywrap .gridinfo .gridmeta {
  transition-delay: 0.25s;
}
.gridywrap .gridinfo .gridmeta p {
  display: inline-block;
  color: #fff;
  margin-bottom: 15px;
}
.gridywrap .gridinfo .gridmeta p i {
  margin-right: 5px;
}
.gridywrap .gridinfo .gridmeta p.gridwho {
  margin-left: 25px;
}
.gridywrap .gridinfo p.gridexerpt {
  width: 60%;
  margin: 0 auto;
  line-height: 22px;
  margin-bottom: 25px;
  color: #fff;
  transition-delay: 0.5s;
}

.gridywrap .gridinfo * {
  opacity: 0;
  transition: all 0.8s;
}

.gridywrap .gridinfo .grid-btn {
  color: #fff;
  text-decoration: none;
  margin: 0 auto;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 25px;
  border: 1px solid #fff; 
  display: block;
  overflow: hidden;
}

.gridywrap .gridinfo .grid-btn span {
  opacity: 0;
  display: none;
  transition: opacity 0.8s;
}
.gridywrap .gridinfo .grid-btn span {
  opacity: 1;
  display: inline;
}
.gridywrap .gridinfo .grid-btn i {
  opacity: 0;
  display: none;
}
.gridywrap .gridinfo .grid-btn {
    width: 75px;
    background: rgba(0,0,0,0.3);
  }

.gridywrap .gridinfo:hover * {
  opacity: 0.7;
}
.gridy-item .label{
  transition: opacity 0.8s;
  color: $rhino;
  text-align:center;
  margin-bottom:.5rem;
}
.gridy-item:hover .label {
  opacity: .7;
}
.gridywrap .gridinfo:hover svg {
  color: white;
  // opacity: 1;
}
.gridywrap .gridinfo:hover p {
  opacity: 1;
}
.gridywrap .gridinfo:hover {
  background: rgba(0,0,0, 0.7);
}
.gridywrap .gridinfo:hover h3 {
  // margin-top: 60px;
  opacity: 1;
}


@media screen and (min-width: 640px) {
  .gridy-item {width:50%;}
  // .gridywrap .gridyhe-2 {height: 320px;}
  // .gridywrap {
  //   width: 100%;
  // }
  // .gridywrap .gridy-1 {width: 50%;}
  // .gridywrap .gridy-2 {width: 50%;}
  // .gridywrap .gridy-3 {width: 100%;}
  .gridy-item .label{
    margin-bottom:0;
  }
  
}
@media screen and (min-width: 1024px) {
  // .gridy-item {width:35%;margin:1%;height:300px;border:1px solid #ccc;}
  .gridy-item {width:33.333%;}
  .row-of-4 .gridy-item {width: 25%;height:200px;}
  .gridywrap .row-of-4 .gridinfo h3:after{
    border:0;
  }
  // .gridywrap .gridy-1 {width: 33.3%;}
  // .gridywrap .gridy-2 {width: 66.6%;}
  // .gridywrap .gridy-3 {width: 100%;}
  // .gridywrap .gridyhe-2 {height: 640px;}
}
