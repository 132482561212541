// CSS
@import-normalize;
* {
    box-sizing: border-box;
}

// @font-face {
//     font-family: 'GTWalsheimProTrial-Thin';
//     src: url('./../fonts/GTWalsheimProTrial-Thin.eot?#iefix') format('embedded-opentype'),  url('./../fonts/GTWalsheimProTrial-Thin.otf')  format('opentype'),
//     url('./../fonts/GTWalsheimProTrial-Thin.woff') format('woff'), url('./../fonts/GTWalsheimProTrial-Thin.ttf')  format('truetype'), url('./../fonts/GTWalsheimProTrial-Thin.svg#GTWalsheimProTrial-Thin') format('svg');
//     font-weight: thin;
//     // font-style: normal;
// }
@font-face {
    font-family: "GT-Walsheim-Pro-Regular";
    src: local("GT-Walsheim-Pro-Regular"),
      url("./../fonts/GT-Walsheim-Pro-Regular.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "GT-Walsheim-Pro-Medium";
    src: local("GT-Walsheim-Pro-Medium"),
      url("./../fonts/GT-Walsheim-Pro-Medium.ttf") format("truetype");
    font-weight: medium;
  }
  @font-face {
    font-family: "GT-Walsheim-Pro-Bold";
    src: local("GT-Walsheim-Pro-Bold"),
      url("./../fonts/GT-Walsheim-Pro-Bold.ttf") format("truetype");
    font-weight: bold;
  }

 @mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin sm {
  @media (min-width: 600px) { @content; }
}
@mixin md {
  @media (min-width: 900px) { @content; }
}
@mixin lg {
  @media (min-width: 1200px) { @content; }
}
@mixin xl {
  @media (min-width: 1800px) { @content; }
}

body{font-family: 'GT-Walsheim-Pro-Regular', sans-serif;}
b,strong, .hero h1 { font-family: 'GT-Walsheim-Pro-Bold', sans-serif; }

$medium: 'GT-Walsheim-Pro-Medium', sans-serif;
// p{max-width: fit-content;}

html{
  font-size: 14px;
  line-height: 1.5;
  h3 { font-size: 18px; }
  h2 { font-size: 26px; }
  h1 { font-size: 48px; }
  @include sm {
    font-size: 15px;
    h3 { font-size: 20px; }
    h2 { font-size: 32px; }
    h1 { font-size: 62px; }
  }  
  @include md  {
    font-size: 16px;
    h3 { font-size: 24px; }
    h2 { font-size: 36px; }
    h1 { font-size: 72px; }
  }
  // @include lg { font-size: 18px}
  // @include xl {
  //   font-size: 20px
  // }
}
h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin-top: 0;
  margin-bottom: .5rem
}
h1, h2, h3, h4, h5, h6{
  margin-bottom: 1rem;
  line-height: 1.1
}
h3{
  line-height: 1.3;
  margin-bottom: 0.6rem;
}
li{margin-bottom:.5rem;}

// Main colors used (https://www.color-blindness.com/color-name-hue/)
$rhino: #2c3160;
$chetwode: #6474af;
$bluenav: #485C80;
$bluebright: #4080ED;
$bluebright-hover: rgb(49, 101, 190);
$solitude: #F3F4F9;
$soft-white: rgba(255,255,255,0.8);
$hard-white: rgba(255,255,255,0.95);
$wisteria: #bc9be0;
$wisteria-hover: #cea2ff;
$rock-blue: #9aaec3;
$maya-blue: #77b4ff;
$fauna-cta: #00caa3;
$fauna-cta-hover: #04a98a;
$soft-border: rgba(0,0,0,0.1);
$soft-line: rgba(0,0,0,0.14);
$soft-shade: #F9F9F9;
$blue-shade: #3A4A66;
$dark-grey: #4A4A4A;
$grey: #808080;
$lovered: #D44D5C;
// $fauna-cerulean: #323fcb;


//NEW
$particlesMain: #6974c8;

$transition: all 0.8s;
$max-width: 1200px;
$vertical-padding: 2%;
a {
  text-decoration: none;}
p a {
  text-decoration: none; font-weight: 500; color: #33358a;
  border-bottom: 1px dotted #6974c894;
  // transition: all 4s;
  // display:inline-block;
  &:hover{  border-bottom: 1px dashed $particlesMain;}
}
ul{padding-left:1rem;}


$border-radius: 10px;

// old layout
$grid-unit: 2rem;
$touch-target-min: 36px;
$touch-target: 44px;
$border-radius-round: calc( #{$grid-unit} / 1.5);

// old
$menu-font-size: 20px;

@import "./components/form";
@import "./components/layout";
@import "./components/nav";
// @import "./components/asset";
@import "./components/gridy";
@import "./pages/home";
// // CSS from libraries  
// https://fkhadra.github.io/react-toastify/how-to-style/
@import "react-toastify/dist/ReactToastify";
:root{
  --toastify-color-success: #07bc0c;
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-text-color-success: #fff;
  --toastify-color-progress-success: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
}
.Toastify__progress-bar--success {
}

.work-in-progress{
  background: rgba(255,255,255,.7);
    color: red;
    padding: 1rem;
    border: 1px solid red;
    z-index: 9999;
    margin:1rem;
}


// iframe, body, html, #root, .page, head, .body-container{overflow-x:hidden;max-width:100%;display:flex;box-sizing:border-box;}
// *{
//   border:1px solid blue;
//   margin:-1px;
// }
// iframe{border:1px solid blue;
//   margin:-1px;}
// html{border:1px solid black;
//   margin:-1px;}
//   body{border:1px solid yellow;
//     margin:-1px;}
