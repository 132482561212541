html {
    min-height: 100%;
    background: $solitude;
}

body {
    margin: 0;
    --header-height: 50px;
    --header-title-height: 35px;
    color: $rhino;
    min-height: 100vh;
}
#root {min-height: 100vh;}
.page{overflow:hidden;}


footer, main, .account-form-container { 
    z-index: 10;
}
nav {
    z-index: 11; //otherwise, the links break
}

.body-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content:space-between;
    align-items: center;
}

main { 
    width: 100%;
    // background: rgba(255,255,255,.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:1rem 0;
}
.hero, section {
    max-width: $max-width;
}
.hero{
    display:flex;
    align-items: center;
    flex-direction: column;
    text-align:center;
    // min-height:40vh;
    padding: 2rem;
    img{max-width: 200px;}
    h1{ margin-bottom:1rem;}
    p, h3{max-width: fit-content;}
    p{text-align: left;}
    h3{
        // margin-top:0px;
        &:before {
            content: '';
            width: 25px;
            border-top: 4px solid $rhino;
            display: block;
            margin: 0 auto;
            // margin-top: 1rem;
            padding-top: 1rem;
        }
    }
}
ul.information{
    list-style-type: none;
    padding: 0;
    span{font-family: $medium;}
}

.main-content{
    background: rgba(255,255,255,.9);   
    display: flex;
    justify-content: center;
    width: 100%;
}

.project-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}
section{
    align-items: center;
    display: flex;
    flex-direction:column;
    padding: 2rem;
    // &:first-child{ padding: 2rem 2rem .5rem 2rem; }
    // &:last-child{ padding: .5rem 2rem 1rem 2rem; }
    .image-wrap,
    .text-wrap{ padding: 1rem; width: 100%;  max-width: 30rem;}
.image-wrap .border{
    border: 1px solid #dde0f2;
}
    @include md{
        flex-direction: row;
        flex: 1;
        .image-wrap,.text-wrap { width: 50%;}
        // &:nth-child(even) {
        //     flex-direction:row-reverse
        // }
    }

}
.image-wrap a{
 display:flex;   
}

section.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    &>div{width:100%;}
}
.center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



footer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #efefef;
    padding: 5%;
    width: 100%;
}


.intro-text{
    h2,h3,h4{text-align:center;}
    ul{
        display:flex; flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left:0;}
    p, li{max-width: 600px;}
    margin-bottom:0;
}
  
img{max-width:100%;}
.thumbnail{max-width:300px;}  

.thumbnails .lg-react-element {
    display:flex; flex-direction: row;
    flex-wrap:wrap;
    a{    text-decoration: none;
        display: flex;
        max-height: 150px;
        overflow: hidden;
        width:33%;
        img{    
            // margin: .5rem;
            // border: 1px solid grey;
            margin: auto;
            padding: 1%;
            box-sizing: border-box;
        }
    // &:first-child{border: 1px solid black}
    }
    button{display:none;}
}
.thumbnails.col-of-2 .lg-react-element {
    a{
        max-height: 250px;
        width:100%;
        margin-bottom: 0.5rem;
    }
}
.thumbnails.row-of-2 .lg-react-element {
    a{
        max-height: 250px;
        width:49%;
        margin-right:.5%;
    }
}

// because they're broken:
// .SRLNextButton, .SRLPrevButton {
//     display: none !important;
//     background-color:transparent !important;
// }
button.SRLNextButton, button.SRLPrevButton{
    position:fixed !important;
    // border:1px solid red;
    display:flex !important;
}
@media (max-width: 768px){
    button.SRLNextButton, button.SRLPrevButton{
        display:flex !important;
    }
}
button.SRLNextButton{
    right: unset !important;
    left: 95vw;
}


.react-transform-component, [class^="transform-component-module_wrapper"]{
    padding: 200px !important;
}