@use "sass:math";

.account-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
   // color: rgba(255,255,255,0.8);
}

.account-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 600px;
    height: 400px;
    background: rgba(255,255,255,0.05);
    padding: 20px;
}

.account-form button {
  min-width: 80px; 
  border-radius: $border-radius-round;
//   margin-left: $grid-unit;
}

.input-row {
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-content: space-between;
    // align-items: center;
    width: 100%;
}

.input-row-column {
    display: flex;
}

input.input-row-column {
    // width: 80%;
}

input[type="submit"], input[type="file"], input[type="email"], input[type="text"], input[type="password"], input:not([type]), select, textarea {
    border: 1px solid $soft-border;
    // padding: math.div($grid-unit, 1.5);
    padding: 0 1rem;
    border-radius: $border-radius-round;
    outline: none;
    font-size: 15px;
}
input[type="submit"], input[type="file"], input[type="email"], input[type="text"], input[type="password"], input:not([type]) {
    height: $touch-target;
}
textarea{
    padding:1rem;
}

.align-right {
    justify-content: flex-end;
}



button {
    // border-radius: $border-radius;
    border: none;
    display: inline-block;
    cursor: pointer;
    height: $touch-target;
    color: white;
    text-decoration: none;
    background: $particlesMain;
    padding: 0 math.div($grid-unit, 1.5);
    text-transform: uppercase;
    text-shadow: 0px 1px 0px rgba(0,0,0,0.2);
    transition: $transition;
}

button:hover {
    background: $rhino;
}
button[class^='SRL']:hover, button[class*=' SRL']:hover{
    background: transparent;
}

button:active {
    position: relative;
    top: 1px;
}

button:focus {
    outline: none;
}
